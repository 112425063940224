<template>
  <div class="list-group-item list-group-item-action" aria-current="true">
    <div class="d-flex w-100 justify-content-start">
      <div class="und-img">
        <a href="/single.php" class="to-single">
          <img :src="beer.img_link" alt="" class="w-100 mb-2">
        </a>
        <a href="#!" class="add-to-cart" @click="add_to_cart(beer)"> в корзину </a>
        <small>{{ beer.country }}</small>
      </div>
      <div class="w-100 beer-info">
        <a href="/single.php" class="to-single">
          <h5 class="mb-1">{{ beer.name }}</h5>
        </a>
        <small>{{ beer.style }}</small>
        <p class="mb-1">{{ beer.abv }}% ABV • {{ beer.ibu }} IBU • {{ beer.brewery }} </p>
        <small>
          <untappdStars :raiting="beer.untappd_stars"></untappdStars>
          &nbsp;&nbsp;&nbsp;
          <a :href="beer.untappd_link" class="und-link">untappd</a>
        </small>
        <p class="dots">
          <span class="size">{{ beer.boxing }}</span>
          <span class="price">{{ beer.price }} р.</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import untappdStars from '@/components/UntappdStars'
var arr = require('lodash');




export default {
  props:['beer'],
  data: function (){
    return {

    }
  },
  components:{untappdStars},
  methods:{
    add_to_cart:function(beer){
      let cloned = arr.cloneDeep(beer);
      this.$store.commit('set_shopcart', cloned)

      this.$msg.info(
           {
              message: "Добавлено в корзину",
              duration: 2000,
              className: 'mess'
            }
          )
    },
  }
}
</script>
<style>
.mess:first-of-type{
  top: 60px;
}
.beer-info{
  text-align: left !important;
  padding-left: 5px;
}
</style>