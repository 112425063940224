<template>
  <div>
    <div class="container text-center mt-3 pl-0"  id="myHeader">
      <div class="row">
        <div class="col-12" id="catalog-title">

          <div class="d-flex justify-content-between w-100 block-params">
            <a href="#!"><i class="fas fa-sort-amount-down"></i></a>

            <div id="catalog-tags">
              <select class="form-select form-select-sm" aria-label=".form-select-sm example">
                <option selected>Все</option>
                <option value="1">Безалкогольное</option>
                <option value="2">Сидр</option>
                <option value="3">Новинки</option>
              </select>
            </div>

            <a href="#!" data-bs-toggle="modal" data-bs-target="#modal-filter"><i class="fas fa-sliders-h"></i>&nbsp; фильтры</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container text-center mt-3 pl-0">
      <div class="row">
        <div class="col-12" id="catalog-title2">
          <div class="list-group beers-list">
            <beerInList v-for="beer in filtred_beers" :beer="beer" :key="beer.id"></beerInList>
          </div>
        </div>
      </div>
    </div>
    <Filter></Filter>
  </div>
</template>
<script>
import beerInList from '@/components/BeerInList'
/*Vue.component('beerInList', require('@/components/BeerInList.vue'));*/

export default{
  components:{beerInList},
  computed:{
    filtred_beers: {
      get(){
        return  this.$store.getters.SHOW_FILTRED_BEERS
      }
    },
  },
  methods:{

  },
  updated() {

  }
}


</script>
<style>
.sticky {
  position: fixed;
  top: 44px;
  left: 0;
  padding: 10px ;
  z-index: 1050;
  transition: 0.2s;
  background: #fff;
  -webkit-box-shadow: -2px 0px 29px 4px rgba(34, 60, 80, 0.15);
  -moz-box-shadow: -2px 0px 29px 4px rgba(34, 60, 80, 0.15);
  box-shadow: -2px 0px 29px 4px rgba(34, 60, 80, 0.15);
}
.myHeader{
  position: relative;
  top: 0;
}
</style>