<template>
  <span>
      <i class="fas fa-star" v-for="full_star in full_stars"></i>
      <i class="fas fa-star-half-alt" v-for="half_star in half_stars" ></i>
      <i class="far fa-star" v-for="empty_star in empty_stars"></i>
      &nbsp;
      <span>{{ raiting }}</span>
  </span>
</template>
<script>
  export default {
      props:['raiting'],
    data:function(){
      return {

      }
    },
    computed:{
      full_stars:function(){
           return Math.trunc( this.raiting)
      },
      half_stars:function(){
        let res = this.raiting - Math.trunc( this.raiting)
          if(res > 0){
            return 1
          }else{
            return 0
          }
      },
      empty_stars:function(){
        return 5 - this.full_stars - this.half_stars
      }
    }
  }
</script>
<style>

</style>